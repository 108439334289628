var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{class:_vm.clickable ? { clickable: true } : '',on:{"click":function($event){_vm.clickable
                ? _vm.$router.push({
                      name: 'HackathonView',
                      params: {
                          hackathon_id: _vm.id,
                      },
                  })
                : null}}},[_c('b-row',[_c('b-col',[_c('h3',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$router.push({
                                name: 'HackathonView',
                                params: {
                                    hackathon_id: _vm.id,
                                },
                            })}}},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_vm._v("   "),(_vm.is_creator)?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                                name: 'HackathonTimeline',
                                params: {
                                    hackathon_id: _vm.id,
                                },
                            })}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e()])]),(_vm.is_participant || _vm.is_creator || _vm.is_manager)?_c('b-col',{staticClass:"pr-1 pl-0",attrs:{"sm":"auto"}},[(_vm.hackathon_is_active(_vm.id))?_c('el-tooltip',{attrs:{"content":"This hackathon is currently active in the sidebar","placement":"top"}},[_c('badge',{attrs:{"rounded":true,"size":"md","type":"primary"}},[_vm._v(" Active ")])],1):_c('el-tooltip',{attrs:{"content":"Makes this hackathon active in the sidebar","placement":"top"}},[_c('base-button',{attrs:{"type":"primary","pill":true,"size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.activate_hackathon()}}},[_vm._v("Activate ")])],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('p',[_vm._v(_vm._s(_vm.description))])])],1),_c('b-row',[_c('b-col',[(_vm.date_in_future(_vm.starts))?_c('h6',{staticClass:"mb-0 text-muted"},[_vm._v(" Starts: "+_vm._s(_vm.starts.substring(0, 10))+" ")]):_vm._e(),(_vm.organization)?_c('h6',{staticClass:"mb-0 text-muted"},[_vm._v(" By: "+_vm._s(_vm.organization)+" ")]):_vm._e()]),_c('b-col',{staticClass:"text-right"},[(_vm.phase)?_c('badge',{attrs:{"rounded":true,"size":"md","type":"info"}},[_vm._v(" "+_vm._s(_vm.phase)+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }